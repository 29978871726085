<button mat-button color="primary" disabled style="width: 100%;">JOGO DA VELHA</button>

<button mat-button style="width: 100%;" *ngIf="vencedor">
  <h3>O jogador {{ vencedor }} ganhou</h3>
</button>

<button mat-button style="width: 100%;" *ngIf="empate">
  <h3>Deu velha/empate nenhum jogador venceu</h3>
</button>
<br>
<mat-grid-list  cols="3" rowHeight="2:1" *ngIf="!vencedor">
  <mat-grid-tile *ngFor="let item of quadrados; let i = index" (click)="jogada(i)">
    <app-quadrado [value]="item"></app-quadrado>
  </mat-grid-tile>
</mat-grid-list>

<br>
<button mat-button color="primary" style="width: 100%;" (click)="novoJogo()">Reiniciar Jogo</button>
