import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'jogo-da-velha';

  quadrados: string[]

  vencedor: string;

  xIsNext: boolean;

  empate: boolean;

  ngOnInit() {
    this.novoJogo();
  }

  novoJogo(): void {
    this.quadrados = Array(9).fill(null);
    this.vencedor = null;
    this.xIsNext = true;
    this.empate = false;
  }

  get jogador(){
    return this.xIsNext ? 'X' : 'O';
  }

  jogada(id: number): void {
    if(!this.quadrados[id]){
      this.quadrados.splice(id, 1, this.jogador);
      this.xIsNext = !this.xIsNext;
    }

    if(this.quadrados.find(x => x === null) === undefined){
      this.empate = true;
    }

    this.vencedor = this.calcularVencedor();
  }

  calcularVencedor() {
    const lines = [
      [0,1,2],
      [3,4,5],
      [6,7,8],
      [0,3,6],
      [1,4,7],
      [2,5,8],
      [0,4,8],
      [2,4,6]
    ];

    for (let i = 0; i < lines.length; i++) {
      const [a, b, c] = lines[i];
      if(
        this.quadrados[a] &&
        this.quadrados[a] === this.quadrados[b] &&
        this.quadrados[a] === this.quadrados[c]
      ){
        return this.quadrados[a];
      }
    }

    return null;
  }
}
